import component from './index.vue'
import Vue from 'vue'

const Loading = {
  install: function () {
    const LoadingConstructor = Vue.extend(component)

    let id = document.getElementById('loading')
    if (id) {
      document.body.removeChild(id)
    }

    const instance = new LoadingConstructor()

    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    Vue.prototype.$loading = (obj) => {
      let text = '加载中...'
      if (obj) {
        text = obj.text
      }

      instance.text = text
      instance.show = true
    }

    Vue.prototype.$cleanLoading = () => {
      instance.show = false
    }
  }
}

export default Loading
